import React, { useState, useEffect, useContext } from 'react';
import './VisitedPosts.css';  // Usamos el mismo archivo de CSS para mantener consistencia
import './Spinner.css'; 
import { AuthContext } from './auth/AuthContext';
import { Link } from 'react-router-dom';  // Para los enlaces de los posts

const Recommendations = () => {
  const [recommendations, setRecommendations] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Empieza en true para mostrar el loading desde el inicio
  const { refreshAccessToken, isLoggedIn } = useContext(AuthContext);

  useEffect(() => {        
    const fetchRecommendations = async () => {
      try {
        setIsLoading(true); // Activa el loading antes de la petición

        const limit = 10;
        const user_id = 7;
        const params = new URLSearchParams({
          limit: limit,
          user_id: user_id,
        });

        const response = await fetch(`${process.env.REACT_APP_API_URL}/recommender?${params}`, {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.status === 401) {
          const tokenRefreshed = await refreshAccessToken();  // Intenta refrescar el token si es necesario
          if (tokenRefreshed) {
            return await fetchRecommendations();  // Intenta de nuevo con el nuevo token
          } else {
            console.error('Unable to refresh token. Please log in again.');
            setIsLoading(false); // Asegura que el loading se detenga
            return;
          }
        } 

        if (!response.ok) {
          console.error('Error fetching recommendations', response.status);
          setIsLoading(false); // Asegura que el loading se detenga
          return;
        }

        const data = await response.json();
        setRecommendations(data.slice(0, 10));  // Limitar las recomendaciones a 10 posts

      } catch (error) {
        console.error('Error fetching recommendations:', error);
      } finally {
        setIsLoading(false); // Detiene el loading en todos los casos
      }
    };

    fetchRecommendations();
  }, []);

  if (!isLoggedIn){
    return (
      <div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="visited-posts-container">
        <h3 className="visited-posts-header">Recommended Posts</h3>
        <div className="loading-container">
          <div className="small-spinner"></div>
          <p>Loading recommendations...</p>
        </div>
      </div>
    );
  }

  if (recommendations.length === 0) {
    return (
      <div className="visited-posts-container">
        <h3 className="visited-posts-header">Recommended Posts</h3>
        <p>No recommendations available at the moment.</p>
      </div>
    );
  }

  return (
    <div className="visited-posts-container">
      <h3 className="visited-posts-header">Recommended Posts</h3>
      <ul className="visited-posts-list">
        {recommendations.map((post) => (
          <li key={post.messageid} className="visited-post-item">
            <Link to={`/post/${post.post_id}`} className="visited-post-link">
              <img
                src={`https://newspotweb.xyz/?page=getimage&messageid=${post.messageid}`}
                alt={post.title}
                className="visited-post-thumbnail"
              />
              <span className="visited-post-title">
                {post.title.length > 20 ? `${post.title.substring(0, 20)}...` : post.title}
              </span>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Recommendations;