import React from 'react';
import { AuthProvider } from './components/auth/AuthContext';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import SpotwebTable from './components/SpotwebTable';
import PostDetail from './components/PostDetail';

function App() {
  return (
   <AuthProvider>
    <Router>
      <div className="App">
        <main>
          <Routes>
              <Route path="/" element={<SpotwebTable />} />
             <Route path="/post/:id" element={<PostDetail />} />
          </Routes>
        </main>
      </div>
    </Router>
   </AuthProvider>	  
  );
}

export default App;

